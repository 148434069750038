<template>
  <div>
    <app-timeline class="p-md-2">
      <app-timeline-item
        :variant="index % 2 == 1 ? 'secondary gradient' : 'primary gradient'"
        v-for="(section, index) in sections"
        :key="index"
        class="p-25 p-md-50 pt-md-5"
        icon="FileTextIcon"
      >
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 class="mr-50 ml-50" v-b-toggle="'report-list-with-icon' + index">
            {{ section.title }}
          </h6>
        </div>
        <!-- <p>Click the button below to read financial reports</p> -->

        <b-collapse :visible="index == 0" :id="'report-list-with-icon' + index">
          <b-list-group flush class="mt-1">
            <b-list-group-item
              v-for="(item, index) in section.items"
              :id="item.id"
              :key="item.id"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <b-row class="w-100">
                <b-col v-if="item.quiz" md="9">
                  <b-row class="m-0">
                    <h4 class="text-dark m-25">{{ index + 1 }} -</h4>
                    <h4 class="text-danger m-25">
                      <feather-icon icon="CheckSquareIcon" />
                    </h4>
                    <h4 class="m-25">{{ item.quiz.title }}</h4>
                  </b-row>
                </b-col>
                <b-col v-else md="9">
                  <b-row class="m-0">
                    <h4 class="text-dark m-25">{{ index + 1 }} -</h4>
                    <h4 class="text-success m-25">
                      <feather-icon icon="FileTextIcon" class="m-25" />
                    </h4>
                    <!-- <b-avatar rounded :src="item.lesson.imageCover" /> -->
                    <h4 class="m-25">
                      {{ item.lesson.title }}
                    </h4>
                  </b-row>

                  <b-row class="w-100">
                    <div
                      v-if="item.is_preview"
                      class="w-100 description-content"
                      v-html="item.lesson.description"
                    ></div>
                  </b-row>
                </b-col>

                <b-col md="3">
                  <b-row align-h="end" class="m-0 h-100" align-v="center">
                    <feather-icon
                      v-if="item.is_preview"
                      class="text-success"
                      icon="EyeIcon"
                    />
                    <feather-icon
                      v-else
                      class="text-danger"
                      icon="EyeOffIcon"
                    />
                  </b-row>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </app-timeline-item>
    </app-timeline>
    <!-- <b-alert
      :variant="index % 2 == 1 ? 'secondary gradient' : 'primary gradient'"
      show
      v-for="(section, index) in sections"
      :key="index"
      class="p-25 p-md-50"
    >
      <h1 class="">
        {{ section.title }}
      </h1>
      <b-card
        v-for="(item, index) in section.items"
        :id="item.id"
        :key="item.id"
        class="m-md-1 p-md-50"
      >
        <b-row>
          <b-col v-if="item.quiz" md="9">
            <b-row class="m-0">
              <h4 class="text-dark m-25">{{ index + 1 }} -</h4>
              <h4 class="text-danger m-25">
                <feather-icon icon="CheckSquareIcon" />
              </h4>
              <h4 class="m-25">{{ item.quiz.title }}</h4>
            </b-row>
          </b-col>
          <b-col v-else md="9">
            <b-row class="m-0">
              <h4 class="text-dark m-25">{{ index + 1 }} -</h4>
              <h4 class="text-success m-25">
                <feather-icon icon="FileTextIcon" class="m-25" />
              </h4>
              <h4 class="m-25">
                {{ item.lesson.title }}
              </h4>
            </b-row>

            <b-row class="w-100">
              <div
                v-if="item.is_preview"
                class="w-100 description-content"
                v-html="item.lesson.description"
              ></div>
            </b-row>
          </b-col>

          <b-col md="3">
            <b-row align-h="end" class="m-0 h-100" align-v="center">
              <feather-icon
                v-if="item.is_preview"
                class="text-success"
                icon="EyeIcon"
              />
              <feather-icon v-else class="text-danger" icon="EyeOffIcon" />
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-alert> -->
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BAlert,
    AppCollapseItem,
    AppCollapse,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: ["sections"],
  data: () => ({}),
  methods: {},
};
</script>
